<template>
  <div id="login">
    <login-panel></login-panel>
    <login-footer></login-footer>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import LoginPanel from './cpns/login-panel'
import  LoginFooter from './cpns/login-footer'
export default defineComponent({
  props: {

  },
  components: {
    LoginPanel,
    LoginFooter
  },
  setup() {



    return {

    }

  }
})
</script>

<style scoped lang="less">
#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}
</style>
